var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseOutlined from '@shopee/solar-icons/CloseOutlined';
import classNames from 'classnames';
import Dialog from 'rc-dialog';
import * as React from 'react';
import Button from '../button';
import { convertLegacyProps } from '../button/button';
import { ConfigContext } from '../config-provider';
import { NoFormStyle } from '../form/context';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { getTransitionName } from '../_util/motion';
import { canUseDocElement } from '../_util/styleChecker';
import warning from '../_util/warning';
import { getConfirmLocale } from './locale';
let mousePosition;
// ref: https://github.com/ant-design/ant-design/issues/15795
const getClickPosition = (e) => {
    mousePosition = {
        x: e.pageX,
        y: e.pageY,
    };
    // 100ms 内发生过点击事件，则从点击位置动画展示
    // 否则直接 zoom 展示
    // 这样可以兼容非点击方式展开
    setTimeout(() => {
        mousePosition = null;
    }, 100);
};
// 只有点击事件支持从鼠标位置动画展开
if (canUseDocElement()) {
    document.documentElement.addEventListener('click', getClickPosition, true);
}
const Modal = props => {
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    const handleCancel = (e) => {
        const { onCancel } = props;
        onCancel === null || onCancel === void 0 ? void 0 : onCancel(e);
    };
    const handleOk = (e) => {
        const { onOk } = props;
        onOk === null || onOk === void 0 ? void 0 : onOk(e);
    };
    warning(!('visible' in props), 'Modal', `\`visible\` will be removed in next major version, please use \`open\` instead.`);
    const renderFooter = (locale) => {
        const { okText, okType, cancelText, confirmLoading } = props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, Object.assign({ onClick: handleCancel }, props.cancelButtonProps), cancelText || locale.cancelText),
            React.createElement(Button, Object.assign({}, convertLegacyProps(okType), { loading: confirmLoading, onClick: handleOk }, props.okButtonProps), okText || locale.okText)));
    };
    const { prefixCls: customizePrefixCls, footer, visible, open, wrapClassName, centered, getContainer, closeIcon, focusTriggerAfterClose = true } = props, restProps = __rest(props, ["prefixCls", "footer", "visible", "open", "wrapClassName", "centered", "getContainer", "closeIcon", "focusTriggerAfterClose"]);
    const prefixCls = getPrefixCls('modal', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const defaultFooter = (React.createElement(LocaleReceiver, { componentName: "Modal", defaultLocale: getConfirmLocale() }, renderFooter));
    const closeIconToRender = (React.createElement("span", { className: `${prefixCls}-close-x` }, closeIcon || React.createElement(CloseOutlined, { className: `${prefixCls}-close-icon` })));
    const wrapClassNameExtended = classNames(wrapClassName, {
        [`${prefixCls}-centered`]: !!centered,
        [`${prefixCls}-wrap-rtl`]: direction === 'rtl',
    });
    return (React.createElement(NoFormStyle, { status: true, override: true },
        React.createElement(Dialog, Object.assign({}, restProps, { getContainer: getContainer === undefined ? getContextPopupContainer : getContainer, prefixCls: prefixCls, wrapClassName: wrapClassNameExtended, footer: footer === undefined ? defaultFooter : footer, visible: open || visible, mousePosition: mousePosition, onClose: handleCancel, closeIcon: closeIconToRender, focusTriggerAfterClose: focusTriggerAfterClose, transitionName: getTransitionName(rootPrefixCls, 'zoom', props.transitionName), maskTransitionName: getTransitionName(rootPrefixCls, 'fade', props.maskTransitionName) }))));
};
Modal.defaultProps = {
    width: 520,
    confirmLoading: false,
    open: false,
    okType: 'primary',
};
export default Modal;
