import RightOutlined from '@shopee/solar-icons/RightOutlined';
import classNames from 'classnames';
import RcCollapse from 'rc-collapse';
import * as React from 'react';
import toArray from 'rc-util/lib/Children/toArray';
import omit from 'rc-util/lib/omit';
import { ConfigContext } from '../config-provider';
import collapseMotion from '../_util/motion';
import { cloneElement } from '../_util/reactNode';
import warning from '../_util/warning';
import CollapsePanel from './CollapsePanel';
const Collapse = props => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className = '', bordered = true, ghost, expandIconPosition = 'start', } = props;
    const prefixCls = getPrefixCls('collapse', customizePrefixCls);
    // Warning if use legacy type `expandIconPosition`
    warning(expandIconPosition !== 'left' && expandIconPosition !== 'right', 'Collapse', '`expandIconPosition` with `left` or `right` is deprecated. Please use `start` or `end` instead.');
    // Align with logic position
    const mergedExpandIconPosition = React.useMemo(() => {
        if (expandIconPosition === 'left') {
            return 'start';
        }
        return expandIconPosition === 'right' ? 'end' : expandIconPosition;
    }, [expandIconPosition]);
    const renderExpandIcon = (panelProps = {}) => {
        const { expandIcon } = props;
        const icon = (expandIcon ? (expandIcon(panelProps)) : (React.createElement(RightOutlined, { rotate: panelProps.isActive ? 90 : undefined })));
        return cloneElement(icon, () => ({
            className: classNames(icon.props.className, `${prefixCls}-arrow`),
        }));
    };
    const collapseClassName = classNames(`${prefixCls}-icon-position-${mergedExpandIconPosition}`, {
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-ghost`]: !!ghost,
    }, className);
    const openMotion = Object.assign(Object.assign({}, collapseMotion), { motionAppear: false, leavedClassName: `${prefixCls}-content-hidden` });
    const getItems = () => {
        const { children } = props;
        return toArray(children).map((child, index) => {
            var _a;
            if ((_a = child.props) === null || _a === void 0 ? void 0 : _a.disabled) {
                const key = child.key || String(index);
                const { disabled, collapsible } = child.props;
                const childProps = Object.assign(Object.assign({}, omit(child.props, ['disabled'])), { key, collapsible: collapsible !== null && collapsible !== void 0 ? collapsible : (disabled ? 'disabled' : undefined) });
                return cloneElement(child, childProps);
            }
            return child;
        });
    };
    return (React.createElement(RcCollapse, Object.assign({ openMotion: openMotion }, props, { expandIcon: renderExpandIcon, prefixCls: prefixCls, className: collapseClassName }), getItems()));
};
Collapse.Panel = CollapsePanel;
export default Collapse;
