import * as React from "react";
import { ConfigContext } from "../config-provider";
const Empty = () => {
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls("empty-img-default");
    return (React.createElement("svg", { className: prefixCls, width: "120", height: "120", viewBox: "0 0 120 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M60 96C93.1371 96 120 90.1797 120 83C120 75.8203 93.1371 70 60 70C26.8629 70 0 75.8203 0 83C0 90.1797 26.8629 96 60 96Z", fill: "#F5F7FA" }),
        React.createElement("path", { d: "M103.125 46.6224L84.1013 25.2162C83.1881 23.7572 81.855 22.875 80.4506 22.875H39.5494C38.145 22.875 36.8119 23.7572 35.8987 25.2144L16.875 46.6243V63.8188H103.125V46.6224Z", stroke: "#DDE1EB", strokeWidth: "1.875" }),
        React.createElement("path", { d: "M78.0244 52.5242C78.0244 49.5371 79.8881 47.0712 82.2 47.0693H103.531V80.8238C103.531 84.7748 101.056 88.0131 98 88.0131H22.5C19.4437 88.0131 16.9688 84.773 16.9688 80.8238V47.0693H37.8C40.1119 47.0693 41.9756 49.5315 41.9756 52.5186V52.5595C41.9756 55.5466 43.86 57.9585 46.17 57.9585H73.83C76.14 57.9585 78.0244 55.5242 78.0244 52.5372V52.5242Z", fill: "#F5F7FA", stroke: "#DDE1EB", strokeWidth: "1.875" })));
};
export default Empty;
