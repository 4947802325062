var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CheckCircleFilled from '@shopee/solar-icons/CheckCircleFilled';
import CheckCircleOutlined from '@shopee/solar-icons/CheckCircleOutlined';
import CloseCircleFilled from '@shopee/solar-icons/CloseCircleFilled';
import CloseCircleOutlined from '@shopee/solar-icons/CloseCircleOutlined';
import CloseOutlined from '@shopee/solar-icons/CloseOutlined';
import ExclamationCircleFilled from '@shopee/solar-icons/ExclamationCircleFilled';
import ExclamationCircleOutlined from '@shopee/solar-icons/ExclamationCircleOutlined';
import InfoCircleFilled from '@shopee/solar-icons/InfoCircleFilled';
import InfoCircleOutlined from '@shopee/solar-icons/InfoCircleOutlined';
import classNames from 'classnames';
import CSSMotion from 'rc-motion';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import getDataOrAriaProps from '../_util/getDataOrAriaProps';
import { replaceElement } from '../_util/reactNode';
import ErrorBoundary from './ErrorBoundary';
const iconMapFilled = {
    success: CheckCircleFilled,
    info: InfoCircleFilled,
    error: CloseCircleFilled,
    warning: ExclamationCircleFilled,
};
const iconMapOutlined = {
    success: CheckCircleOutlined,
    info: InfoCircleOutlined,
    error: CloseCircleOutlined,
    warning: ExclamationCircleOutlined,
};
const IconNode = props => {
    const { description, icon, prefixCls, type } = props;
    const iconType = (description ? iconMapOutlined : iconMapFilled)[type] || null;
    if (icon) {
        return replaceElement(icon, React.createElement("span", { className: `${prefixCls}-icon` }, icon), () => ({
            className: classNames(`${prefixCls}-icon`, {
                [icon.props.className]: icon.props.className,
            }),
        }));
    }
    return React.createElement(iconType, { className: `${prefixCls}-icon` });
};
const CloseIcon = props => {
    const { isClosable, closeText, prefixCls, closeIcon, handleClose } = props;
    return isClosable ? (React.createElement("button", { type: "button", onClick: handleClose, className: `${prefixCls}-close-icon`, tabIndex: 0 }, closeText ? React.createElement("span", { className: `${prefixCls}-close-text` }, closeText) : closeIcon)) : null;
};
const Alert = (_a) => {
    var { description, prefixCls: customizePrefixCls, message, banner, className = '', style, onMouseEnter, onMouseLeave, onClick, afterClose, showIcon, closable, closeText, closeIcon = React.createElement(CloseOutlined, null), action } = _a, props = __rest(_a, ["description", "prefixCls", "message", "banner", "className", "style", "onMouseEnter", "onMouseLeave", "onClick", "afterClose", "showIcon", "closable", "closeText", "closeIcon", "action"]);
    const [closed, setClosed] = React.useState(false);
    const ref = React.useRef();
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('alert', customizePrefixCls);
    const handleClose = (e) => {
        var _a;
        setClosed(true);
        (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props, e);
    };
    const getType = () => {
        const { type } = props;
        if (type !== undefined) {
            return type;
        }
        // banner 模式默认为警告
        return banner ? 'warning' : 'info';
    };
    // closeable when closeText is assigned
    const isClosable = closeText ? true : closable;
    const type = getType();
    // banner 模式默认有 Icon
    const isShowIcon = banner && showIcon === undefined ? true : showIcon;
    const alertCls = classNames(prefixCls, `${prefixCls}-${type}`, {
        [`${prefixCls}-with-description`]: !!description,
        [`${prefixCls}-no-icon`]: !isShowIcon,
        [`${prefixCls}-banner`]: !!banner,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const dataOrAriaProps = getDataOrAriaProps(props);
    return (React.createElement(CSSMotion, { visible: !closed, motionName: `${prefixCls}-motion`, motionAppear: false, motionEnter: false, onLeaveStart: node => ({
            maxHeight: node.offsetHeight,
        }), onLeaveEnd: afterClose }, ({ className: motionClassName, style: motionStyle }) => (React.createElement("div", Object.assign({ ref: ref, "data-show": !closed, className: classNames(alertCls, motionClassName), style: Object.assign(Object.assign({}, style), motionStyle), onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: onClick, role: "alert" }, dataOrAriaProps),
        isShowIcon ? (React.createElement(IconNode, { description: description, icon: props.icon, prefixCls: prefixCls, type: type })) : null,
        React.createElement("div", { className: `${prefixCls}-content` },
            message ? React.createElement("div", { className: `${prefixCls}-message` }, message) : null,
            description ? React.createElement("div", { className: `${prefixCls}-description` }, description) : null),
        action ? React.createElement("div", { className: `${prefixCls}-action` }, action) : null,
        React.createElement(CloseIcon, { isClosable: !!isClosable, closeText: closeText, prefixCls: prefixCls, closeIcon: closeIcon, handleClose: handleClose })))));
};
Alert.ErrorBoundary = ErrorBoundary;
export default Alert;
