var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import LoadingOutlined from '@shopee/solar-icons/LoadingOutlined';
import classNames from 'classnames';
import RcSwitch from 'rc-switch';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import DisabledContext from '../config-provider/DisabledContext';
import SizeContext from '../config-provider/SizeContext';
import warning from '../_util/warning';
import Wave from '../_util/wave';
const Switch = React.forwardRef((_a, ref) => {
    var { prefixCls: customizePrefixCls, size: customizeSize, disabled: customDisabled, loading, className = '' } = _a, props = __rest(_a, ["prefixCls", "size", "disabled", "loading", "className"]);
    warning('checked' in props || !('value' in props), 'Switch', '`value` is not a valid prop, do you mean `checked`?');
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled || disabled || loading;
    const prefixCls = getPrefixCls('switch', customizePrefixCls);
    const loadingIcon = (React.createElement("div", { className: `${prefixCls}-handle` }, loading && React.createElement(LoadingOutlined, { className: `${prefixCls}-loading-icon` })));
    const classes = classNames({
        [`${prefixCls}-small`]: (customizeSize || size) === 'small',
        [`${prefixCls}-loading`]: loading,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    return (React.createElement(Wave, { insertExtraNode: true },
        React.createElement(RcSwitch, Object.assign({}, props, { prefixCls: prefixCls, className: classes, disabled: mergedDisabled, ref: ref, loadingIcon: loadingIcon }))));
});
Switch.__ANT_SWITCH = true;
if (process.env.NODE_ENV !== 'production') {
    Switch.displayName = 'Switch';
}
export default Switch;
