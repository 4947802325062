import HolderOutlined from '@shopee/solar-icons/HolderOutlined';
import classNames from 'classnames';
import RcTree, { TreeNode } from 'rc-tree';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import collapseMotion from '../_util/motion';
import DirectoryTree from './DirectoryTree';
import dropIndicatorRender from './utils/dropIndicator';
import renderSwitcherIcon from './utils/iconUtil';
const Tree = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction, virtual } = React.useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className, showIcon, showLine, switcherIcon, blockNode, children, checkable, selectable, draggable, } = props;
    const prefixCls = getPrefixCls('tree', customizePrefixCls);
    const newProps = Object.assign(Object.assign({}, props), { showLine: Boolean(showLine), dropIndicatorRender });
    const draggableConfig = React.useMemo(() => {
        if (!draggable) {
            return false;
        }
        let mergedDraggable = {};
        switch (typeof draggable) {
            case 'function':
                mergedDraggable.nodeDraggable = draggable;
                break;
            case 'object':
                mergedDraggable = Object.assign({}, draggable);
                break;
            default:
            // Do nothing
        }
        if (mergedDraggable.icon !== false) {
            mergedDraggable.icon = mergedDraggable.icon || React.createElement(HolderOutlined, null);
        }
        return mergedDraggable;
    }, [draggable]);
    return (React.createElement(RcTree, Object.assign({ itemHeight: 20, ref: ref, virtual: virtual }, newProps, { prefixCls: prefixCls, className: classNames({
            [`${prefixCls}-icon-hide`]: !showIcon,
            [`${prefixCls}-block-node`]: blockNode,
            [`${prefixCls}-unselectable`]: !selectable,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className), direction: direction, checkable: checkable ? React.createElement("span", { className: `${prefixCls}-checkbox-inner` }) : checkable, selectable: selectable, switcherIcon: (nodeProps) => renderSwitcherIcon(prefixCls, switcherIcon, showLine, nodeProps), draggable: draggableConfig }), children));
});
Tree.TreeNode = TreeNode;
Tree.DirectoryTree = DirectoryTree;
Tree.defaultProps = {
    checkable: false,
    selectable: true,
    showIcon: false,
    motion: Object.assign(Object.assign({}, collapseMotion), { motionAppear: false }),
    blockNode: false,
};
export default Tree;
