import * as React from 'react';
import classNames from 'classnames';
// import ArrowLeftOutlined from '@shopee/solar-icons/ArrowLeftOutlined';
// import ArrowRightOutlined from '@shopee/solar-icons/ArrowRightOutlined';
import LeftOutlined from '@shopee/solar-icons/LeftOutlined';
import RightOutlined from '@shopee/solar-icons/RightOutlined';
import ResizeObserver from 'rc-resize-observer';
import useState from 'rc-util/lib/hooks/useState';
import Avatar from '../avatar';
import Breadcrumb from '../breadcrumb';
import { ConfigConsumer } from '../config-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import Space from '../space';
import TransButton from '../_util/transButton';
const renderBack = (prefixCls, backIcon, onBack) => {
    if (!backIcon || !onBack) {
        return null;
    }
    return (React.createElement(LocaleReceiver, { componentName: "PageHeader" }, ({ back }) => (React.createElement("div", { className: `${prefixCls}-back` },
        React.createElement(TransButton, { onClick: (e) => {
                onBack === null || onBack === void 0 ? void 0 : onBack(e);
            }, className: `${prefixCls}-back-button`, "aria-label": back }, backIcon)))));
};
const renderBreadcrumb = (breadcrumb) => React.createElement(Breadcrumb, Object.assign({}, breadcrumb));
const getBackIcon = (props, direction = 'ltr') => {
    if (props.backIcon !== undefined) {
        return props.backIcon;
    }
    return direction === 'rtl' ? React.createElement(RightOutlined, null) : React.createElement(LeftOutlined, null);
};
const renderTitle = (prefixCls, props, direction = 'ltr') => {
    const { title, avatar, subTitle, tags, extra, onBack } = props;
    const headingPrefixCls = `${prefixCls}-heading`;
    const hasHeading = title || subTitle || tags || extra;
    // If there is nothing, return a null
    if (!hasHeading) {
        return null;
    }
    const backIcon = getBackIcon(props, direction);
    const backIconDom = renderBack(prefixCls, backIcon, onBack);
    const hasTitle = backIconDom || avatar || hasHeading;
    return (React.createElement("div", { className: headingPrefixCls },
        hasTitle && (React.createElement("div", { className: `${headingPrefixCls}-left` },
            backIconDom,
            avatar && React.createElement(Avatar, Object.assign({}, avatar)),
            title && (React.createElement("span", { className: `${headingPrefixCls}-title`, title: typeof title === 'string' ? title : undefined }, title)),
            subTitle && (React.createElement("span", { className: `${headingPrefixCls}-sub-title`, title: typeof subTitle === 'string' ? subTitle : undefined }, subTitle)),
            tags && React.createElement("span", { className: `${headingPrefixCls}-tags` }, tags))),
        extra && (React.createElement("span", { className: `${headingPrefixCls}-extra` },
            React.createElement(Space, null, extra)))));
};
const renderFooter = (prefixCls, footer) => {
    if (footer) {
        return React.createElement("div", { className: `${prefixCls}-footer` }, footer);
    }
    return null;
};
const renderChildren = (prefixCls, children) => (React.createElement("div", { className: `${prefixCls}-content` }, children));
const PageHeader = props => {
    const [compact, updateCompact] = useState(false);
    const onResize = ({ width }) => {
        updateCompact(width < 768, true);
    };
    return (React.createElement(ConfigConsumer, null, ({ getPrefixCls, pageHeader, direction }) => {
        var _a;
        const { prefixCls: customizePrefixCls, style, footer, children, breadcrumb, breadcrumbRender, className: customizeClassName, } = props;
        let ghost = true;
        // Use `ghost` from `props` or from `ConfigProvider` instead.
        if ('ghost' in props) {
            ghost = props.ghost;
        }
        else if (pageHeader && 'ghost' in pageHeader) {
            ghost = pageHeader.ghost;
        }
        const prefixCls = getPrefixCls('page-header', customizePrefixCls);
        const getDefaultBreadcrumbDom = () => {
            if (breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.routes) {
                return renderBreadcrumb(breadcrumb);
            }
            return null;
        };
        const defaultBreadcrumbDom = getDefaultBreadcrumbDom();
        const isBreadcrumbComponent = breadcrumb && 'props' in breadcrumb;
        // support breadcrumbRender function
        const breadcrumbRenderDomFromProps = (_a = breadcrumbRender === null || breadcrumbRender === void 0 ? void 0 : breadcrumbRender(props, defaultBreadcrumbDom)) !== null && _a !== void 0 ? _a : defaultBreadcrumbDom;
        const breadcrumbDom = isBreadcrumbComponent ? breadcrumb : breadcrumbRenderDomFromProps;
        const className = classNames(prefixCls, customizeClassName, {
            'has-breadcrumb': !!breadcrumbDom,
            'has-footer': !!footer,
            [`${prefixCls}-ghost`]: ghost,
            [`${prefixCls}-rtl`]: direction === 'rtl',
            [`${prefixCls}-compact`]: compact,
        });
        return (React.createElement(ResizeObserver, { onResize: onResize },
            React.createElement("div", { className: className, style: style },
                breadcrumbDom,
                renderTitle(prefixCls, props, direction),
                children && renderChildren(prefixCls, children),
                renderFooter(prefixCls, footer))));
    }));
};
export default PageHeader;
