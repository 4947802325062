var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CheckCircleFilled from '@shopee/solar-icons/CheckCircleFilled';
import CloseCircleFilled from '@shopee/solar-icons/CloseCircleFilled';
import ExclamationCircleFilled from '@shopee/solar-icons/ExclamationCircleFilled';
import LoadingOutlined from '@shopee/solar-icons/LoadingOutlined';
import useLayoutEffect from 'rc-util/lib/hooks/useLayoutEffect';
import classNames from 'classnames';
import * as React from 'react';
import omit from 'rc-util/lib/omit';
import { Row } from '../../grid';
import FormItemLabel from '../FormItemLabel';
import FormItemInput from '../FormItemInput';
import { FormContext, FormItemInputContext, NoStyleItemContext } from '../context';
import useDebounce from '../hooks/useDebounce';
const iconMap = {
    success: CheckCircleFilled,
    warning: ExclamationCircleFilled,
    error: CloseCircleFilled,
    validating: LoadingOutlined,
};
export default function ItemHolder(props) {
    const { prefixCls, className, style, help, errors, warnings, validateStatus, meta, hasFeedback, hidden, children, fieldId, isRequired, onSubItemMetaChange } = props, restProps = __rest(props, ["prefixCls", "className", "style", "help", "errors", "warnings", "validateStatus", "meta", "hasFeedback", "hidden", "children", "fieldId", "isRequired", "onSubItemMetaChange"]);
    const itemPrefixCls = `${prefixCls}-item`;
    const { requiredMark } = React.useContext(FormContext);
    // ======================== Margin ========================
    const itemRef = React.useRef(null);
    const debounceErrors = useDebounce(errors);
    const debounceWarnings = useDebounce(warnings);
    const hasHelp = help !== undefined && help !== null;
    const hasError = !!(hasHelp || errors.length || warnings.length);
    const [marginBottom, setMarginBottom] = React.useState(null);
    useLayoutEffect(() => {
        if (hasError && itemRef.current) {
            const itemStyle = getComputedStyle(itemRef.current);
            setMarginBottom(parseInt(itemStyle.marginBottom, 10));
        }
    }, [hasError]);
    const onErrorVisibleChanged = (nextVisible) => {
        if (!nextVisible) {
            setMarginBottom(null);
        }
    };
    // ======================== Status ========================
    let mergedValidateStatus = '';
    if (validateStatus !== undefined) {
        mergedValidateStatus = validateStatus;
    }
    else if (meta.validating) {
        mergedValidateStatus = 'validating';
    }
    else if (debounceErrors.length) {
        mergedValidateStatus = 'error';
    }
    else if (debounceWarnings.length) {
        mergedValidateStatus = 'warning';
    }
    else if (meta.touched) {
        mergedValidateStatus = 'success';
    }
    const formItemStatusContext = React.useMemo(() => {
        let feedbackIcon;
        if (hasFeedback) {
            const IconNode = mergedValidateStatus && iconMap[mergedValidateStatus];
            feedbackIcon = IconNode ? (React.createElement("span", { className: classNames(`${itemPrefixCls}-feedback-icon`, `${itemPrefixCls}-feedback-icon-${mergedValidateStatus}`) },
                React.createElement(IconNode, null))) : null;
        }
        return {
            status: mergedValidateStatus,
            hasFeedback,
            feedbackIcon,
            isFormItemInput: true,
        };
    }, [mergedValidateStatus, hasFeedback]);
    // ======================== Render ========================
    const itemClassName = {
        [itemPrefixCls]: true,
        [`${itemPrefixCls}-with-help`]: hasHelp || debounceErrors.length || debounceWarnings.length,
        [`${className}`]: !!className,
        // Status
        [`${itemPrefixCls}-has-feedback`]: mergedValidateStatus && hasFeedback,
        [`${itemPrefixCls}-has-success`]: mergedValidateStatus === 'success',
        [`${itemPrefixCls}-has-warning`]: mergedValidateStatus === 'warning',
        [`${itemPrefixCls}-has-error`]: mergedValidateStatus === 'error',
        [`${itemPrefixCls}-is-validating`]: mergedValidateStatus === 'validating',
        [`${itemPrefixCls}-hidden`]: hidden,
    };
    return (React.createElement("div", { className: classNames(itemClassName), style: style, ref: itemRef },
        React.createElement(Row, Object.assign({ className: `${itemPrefixCls}-row` }, omit(restProps, [
            '_internalItemRender',
            'colon',
            'dependencies',
            'extra',
            'fieldKey',
            'getValueFromEvent',
            'getValueProps',
            'htmlFor',
            'id',
            'initialValue',
            'isListField',
            'label',
            'labelAlign',
            'labelCol',
            'labelWrap',
            'messageVariables',
            'name',
            'normalize',
            'noStyle',
            'preserve',
            'required',
            'requiredMark',
            'rules',
            'shouldUpdate',
            'trigger',
            'tooltip',
            'validateFirst',
            'validateTrigger',
            'valuePropName',
            'wrapperCol',
        ])),
            React.createElement(FormItemLabel, Object.assign({ htmlFor: fieldId, required: isRequired, requiredMark: requiredMark }, props, { prefixCls: prefixCls })),
            React.createElement(FormItemInput, Object.assign({}, props, meta, { errors: debounceErrors, warnings: debounceWarnings, prefixCls: prefixCls, status: mergedValidateStatus, help: help, marginBottom: marginBottom, onErrorVisibleChanged: onErrorVisibleChanged }),
                React.createElement(NoStyleItemContext.Provider, { value: onSubItemMetaChange },
                    React.createElement(FormItemInputContext.Provider, { value: formItemStatusContext }, children)))),
        !!marginBottom && (React.createElement("div", { className: `${itemPrefixCls}-margin-offset`, style: {
                marginBottom: -marginBottom,
            } }))));
}
