import CloseCircleFilled from '@shopee/solar-icons/CloseCircleFilled';
import classNames from 'classnames';
import * as React from 'react';
import { FormItemInputContext } from '../form/context';
import { cloneElement } from '../_util/reactNode';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import { tuple } from '../_util/type';
const ClearableInputType = tuple('text', 'input');
function hasAddon(props) {
    return !!(props.addonBefore || props.addonAfter);
}
class ClearableLabeledInput extends React.Component {
    renderClearIcon(prefixCls) {
        const { value, disabled, readOnly, handleReset, suffix } = this.props;
        const needClear = !disabled && !readOnly && value;
        const className = `${prefixCls}-clear-icon`;
        return (React.createElement(CloseCircleFilled, { onClick: handleReset, 
            // Do not trigger onBlur when clear input
            // https://github.com/ant-design/ant-design/issues/31200
            onMouseDown: e => e.preventDefault(), className: classNames({
                [`${className}-hidden`]: !needClear,
                [`${className}-has-suffix`]: !!suffix,
            }, className), role: "button" }));
    }
    renderTextAreaWithClearIcon(prefixCls, element, statusContext) {
        const { value, allowClear, className, style, direction, bordered, hidden, status: customStatus, } = this.props;
        const { status: contextStatus, hasFeedback } = statusContext;
        if (!allowClear) {
            return cloneElement(element, {
                value,
            });
        }
        const affixWrapperCls = classNames(`${prefixCls}-affix-wrapper`, `${prefixCls}-affix-wrapper-textarea-with-clear-btn`, getStatusClassNames(`${prefixCls}-affix-wrapper`, getMergedStatus(contextStatus, customStatus), hasFeedback), {
            [`${prefixCls}-affix-wrapper-rtl`]: direction === 'rtl',
            [`${prefixCls}-affix-wrapper-borderless`]: !bordered,
            // className will go to addon wrapper
            [`${className}`]: !hasAddon(this.props) && className,
        });
        return (React.createElement("span", { className: affixWrapperCls, style: style, hidden: hidden },
            cloneElement(element, {
                style: null,
                value,
            }),
            this.renderClearIcon(prefixCls)));
    }
    render() {
        return (React.createElement(FormItemInputContext.Consumer, null, statusContext => {
            const { prefixCls, inputType, element } = this.props;
            if (inputType === ClearableInputType[0]) {
                return this.renderTextAreaWithClearIcon(prefixCls, element, statusContext);
            }
        }));
    }
}
export default ClearableLabeledInput;
